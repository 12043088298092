<template>
  <transition name="modal-fade" appear>
    <div v-if="isOpen" class="modal-overlay">
      <div class="modal">
        <div class="modal-content">
          <slot />
        </div>
        <span class="modal-close" @click="closeModal">&times;</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobileFullScreenLargeDesktopModal',
  props: {
    isOpen: Boolean,
  },
  emits: ['close'],
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  padding-top: 10px;
  max-width: 700px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  text-align: -webkit-center;
  border-radius: 5px;
}

.modal-content {
  padding: 20px;
}

.modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 35px;
  color: #333;
  padding: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.modal-fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.modal-fade-leave-from {
  opacity: 1;
  transform: translateY(0);
  transition:
    opacity 0.3s,
    transform 0.3s;
}

@media (min-width: 768px) {
  .modal {
    height: 80%;
  }
}
</style>
