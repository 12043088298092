<template>
  <div>
    <div v-if="!isLoaded" class="image-placeholder" />

    <img
      v-else
      :src="require('@/assets/holiday-banner.jpeg')"
      alt="Travel"
      class="banner-image"
      @load="imageLoaded"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoaded: true,
    };
  },
  methods: {
    imageLoaded() {
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped>
.banner-image {
  width: 100%;
  margin-top: 20px;
  height: 240px;
  border-radius: 10px;
  object-fit: cover;
}

.image-placeholder {
  width: 100%;
  height: 240px;
  margin-top: 20px;
  border-radius: 10px;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@media (min-width: 1024px) {
  .banner-image {
    width: 700px;
    height: 460px;
  }

  .image-placeholder {
    width: 700px;
    height: 460px;
    justify-self: center;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
