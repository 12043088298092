<template>
  <ViewLayout @create-trip="createTrip">
    <NotFoundContent
      v-show="isDestinationNotFound"
      :not-found-content="'la destination'"
    />
    <div
      v-if="destinationSpecificationLoading"
      id="preloader"
      ref="preloader"
      class="preloader"
    >
      <div class="preloader-container">
        <h1 class="loading-msg">
          Encore un peu de patience, nous générons vos vacances !
        </h1>
        <div class="preloader-circle" />
      </div>
    </div>

    <span v-if="errorMsg" class="error-message">{{ errorMsg }}</span>

    <div v-if="destination" class="destination-container">
      <h2>
        {{ destination.shortDescription }}
      </h2>
      <div class="destination-summary">
        <div class="destination-summary-info">
          <div class="destination-summary-date-container">
            <label>Votre séjour du </label>
            <span class="destination-summary-date">{{
              formatDate(destination.hotel.checkIn)
            }}</span>
            au
            <span class="destination-summary-date">{{
              formatDate(destination.hotel.checkOut)
            }}</span>
          </div>
          <div v-if="destination.distance > 0">
            À <strong>{{ destination.distance }} km</strong> de chez vous
          </div>
          <VehicleImage
            v-for="(vehicle, index) in destination.vehicles"
            :key="index"
            :vehicle-name="vehicle"
          />
          <div>
            De <strong>{{ destination.minTemperature }}°C</strong> à
            <strong>{{ destination.maxTemperature }}°C</strong>
          </div>
          <WeatherComponent
            :min-temperature="destination.minTemperature"
            :max-temperature="destination.maxTemperature"
          />
          <p class="centered-paragraph">
            {{ destination.description }}
          </p>
          <a
            class="destination-city-link"
            :href="destination.website"
            target="_blank"
            rel="noopener noreferrer"
          >
            Voir le site de la ville
          </a>
        </div>

        <HotelCard :hotel="destination.hotel" />
      </div>

      <h2 class="title-with-separator">Exemples d'activités</h2>

      <div class="planning-container">
        <ActivityCard
          v-for="(activity, index) in destination.planning"
          :key="index"
          :activity="activity"
        />
      </div>
    </div>

    <div class="sticky-footer">
      <ButtonWithGradient
        v-show="destination"
        class="button-footer"
        message="Choisir cette destination"
        @click="createTrip()"
      />
    </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue';
import HotelCard from '@/components/Cards/HotelCard.vue';
import ActivityCard from '@/components/Cards/ActivityCard.vue';
import VehicleImage from '@/components/VehicleImage.vue';
import WeatherComponent from '@/components/Base/WeatherComponent.vue';
import smartVacancesApiClient from '@/SmartVacancesApiClient';
import ButtonWithGradient from '@/components/Base/ButtonWithGradient.vue';
import { mapActions, mapGetters } from 'vuex';
import NotFoundContent from '@/components/NotFoundContent.vue';

export default {
  name: 'SpecifiedDestinationOverview',
  components: {
    ButtonWithGradient,
    WeatherComponent,
    VehicleImage,
    ActivityCard,
    HotelCard,
    ViewLayout,
    NotFoundContent,
  },
  data() {
    return {
      isDestinationNotFound: false,
      destinationSpecificationLoading: true,
      errorMsg: null,
      destination: null,
      destinationWeatherIcon: null,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  mounted() {
    this.fetchDestinationSpecification(this.$route.params.destinationId);
  },
  methods: {
    ...mapActions(['setShouldDisplayTripCreationBtn']),
    async fetchDestinationSpecification(destinationId) {
      try {
        const response = await smartVacancesApiClient.get(
          `v1/destinations/${destinationId}/specification`,
        );
        this.destinationSpecificationLoading = false;
        this.destination = response.data.data;
        this.setShouldDisplayTripCreationBtn(true);
      } catch (error) {
        let errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la spécification. Ré-essayez ultérieurement !';
        if (errorMsg === 'Error while specifying destination') {
          errorMsg =
            "Une erreur est survenue lors de la spécification de la destination. Elle est indépendante de votre requête. Nous vous remercions de bien vouloir l'effectuer de nouveau ultérieurement, et nous excusons pour le désagrément.";
        }
        this.destinationSpecificationLoading = false;
        if (error.response.status === 404) {
          this.isDestinationNotFound = true;
          return;
        }
        this.errorMsg = errorMsg;
      }
    },
    formatDate(dateStr) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const date = new Date(dateStr);

      return date.toLocaleDateString('fr-FR', options);
    },
    async createTrip() {
      try {
        if (!this.isAuthenticated) {
          this.emitter.emit('show-login-modal');

          return;
        }

        const response = await smartVacancesApiClient.post('v1/trips', {
          destination_id: this.$route.params.destinationId,
        });

        this.$router.push({
          name: 'Trip',
          params: { tripId: response.data.data.id },
        });
      } catch (error) {
        console.error('Erreur lors de la création du voyage :', error);
      }
    },
  },
};
</script>

<style>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s ease;
}

.preloader-container {
  text-align: center;
  text-align: -webkit-center;
}

.preloader-circle {
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: preloader-spin 1s linear infinite;
}

@keyframes preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.planning-container {
  display: block;
  padding: 0 20px 20px;
}

.destination-summary {
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.destination-summary-info {
  margin-bottom: 20px;
}

.destination-summary-date {
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  color: white;
  font-weight: 800;
  padding: 1px 3px;
  border-radius: 5px;
  text-shadow: 0.5px 0.5px black;
}

.destination-summary-date-container {
  margin: 10px;
}

.destination-city-link {
  text-decoration: underline;
  color: unset;
  letter-spacing: 1px;
}

.destination-city-link:hover {
  color: var(--primaryColor);
}

.activity-content .address {
  font-size: 13px;
  display: block;
  margin: 0;
}

.sticky-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  text-align: center;
  z-index: 100;
  margin-bottom: 30px;
}

.button-footer {
  padding: 10px 20px;
  background-color: var(--primaryColor);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-footer:hover {
  background-color: var(--hoverColor);
}

.loading-msg {
  font-size: 20px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .destination-container {
    text-align: -webkit-center;
  }

  .button-footer {
    width: 100%;
  }

  .sticky-footer {
    margin-bottom: 5px;
  }
}

@media (min-width: 1024px) {
  .destination-summary {
    display: flex;
    flex-direction: row;
  }

  .loading-msg {
    font-size: 30px;
    margin-bottom: 45px;
  }

  .destination-summary > * {
    width: 50%;
  }

  .planning-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
}
</style>
