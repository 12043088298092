<template>
  <div class="activity-card">
    <div class="activity-image">
      <img :src="imageSrc" alt="Activity Image" />
    </div>

    <div class="activity-content">
      <h3>{{ activity.name }}</h3>
      <p>{{ activity.description }}</p>

      <div v-if="activity.estimated_cost_by_person > 0" class="price">
        <span>{{ activity.estimated_cost_by_person }} €</span>
        <span>/ personne</span>
      </div>
      <div v-else class="free">
        <span>Gratuit</span>
      </div>

      <AddressWithPin v-if="activity.address" :address="activity.address" />
    </div>
  </div>
</template>

<script>
import AddressWithPin from '@/components/AddressWithPin.vue';

export default {
  name: 'ActivityCard',
  components: { AddressWithPin },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    imageSrc: {
      type: String,
      default:
        'https://static.vecteezy.com/ti/vecteur-libre/t2/3002270-randonnee-activite-en-montagne-vectoriel.jpg',
    },
  },
};
</script>

<style scoped>
.activity-card {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  margin: 20px 0;
  transition: transform 0.3s ease;
}

.activity-card:hover {
  transform: translateY(-10px);
}

.activity-image img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eee;
}

.activity-content {
  padding: 20px;
  text-align: center;
}

.activity-content h3 {
  font-size: 20px;
  margin: 0 0 10px;
}

.activity-content p {
  color: #666;
  font-size: 14px;
}

.price {
  align-items: baseline;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.free {
  align-items: baseline;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: seagreen;
}

.price span:last-child {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  margin-left: 5px;
}
</style>
