export function setObjectInCookie(cookieName, object, ttlInDays) {
  const serializedObject = JSON.stringify(object);
  const encodedObject = encodeURIComponent(serializedObject);
  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + ttlInDays);
  document.cookie = `${cookieName}=${encodedObject}; expires=${expirationDate.toUTCString()}; path=/`;
}

export function getObjectFromCookie(cookieName) {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  const cookieObj = cookies.find((cookie) =>
    cookie.startsWith(cookieName + '='),
  );

  if (!cookieObj) {
    return null;
  }

  const encodedStr = cookieObj.split('=')[1];
  const jsonStr = decodeURIComponent(encodedStr);

  try {
    return JSON.parse(jsonStr);
  } catch (error) {
    console.error('Error parsing cookie "' + cookieName + '":', error);
    return null;
  }
}
