<template>
  <ViewLayout>
    <NotFoundContent v-show="isTripNotFound" :not-found-content="'le voyage'" />

    <span v-if="errorMsg" class="error-message">{{ errorMsg }}</span>

    <ConfirmationModal
      v-if="isDeleteModalOpened"
      :is-open="false"
      msg="Êtes-vous sûr de vouloir supprimer cette activité ?"
      @confirmation-modal-yes="confirmDeleteActivity"
      @confirmation-modal-no="closeDeleteConfirmationModal"
      @close="closeDeleteConfirmationModal"
    />

    <ChooseActivityModal
      v-if="isChooseActivityModalOpened"
      :is-open="false"
      :activities="activitiesToChooseFrom"
      @activity-chosen="handleChosenActivity"
      @close="closeActivityChooseModal"
    >
      <div
        v-if="chooseActivityModalType === chooseActivityType.ADD"
        class="choose-activity-content"
      >
        <h2>Sélectionnez une activité à ajouter</h2>
        <label>Activités à {{ trip.destinationCity }}</label>
      </div>
      <div
        v-if="chooseActivityModalType === chooseActivityType.REPLACE"
        class="choose-activity-content"
      >
        <h2>
          Sélectionnez une activité pour remplacer
          <label class="primary-color-label">{{
            activityToReplace.name
          }}</label>
        </h2>
        <label class="category-title">Activités similaires</label>
      </div>
    </ChooseActivityModal>

    <div v-if="trip" class="trip-container">
      <h2>Votre voyage à</h2>
      <h2 class="primary-color-label">
        {{ trip.destinationCity }}
      </h2>
      <div class="trip-summary">
        <div class="trip-summary-info">
          <p class="centered-paragraph">
            {{ trip.description }}
          </p>
          <div class="trip-summary-date-container">
            <span class="trip-summary-date">{{
              formatDate(trip.departureDate)
            }}</span>
            au
            <span class="trip-summary-date">{{
              formatDate(trip.returnDate)
            }}</span>
          </div>
          <div>
            <div>
              De <strong>{{ trip.minTemperature }}°C</strong> à
              <strong>{{ trip.maxTemperature }}°C</strong>
            </div>
            <WeatherComponent
              :min-temperature="trip.minTemperature"
              :max-temperature="trip.maxTemperature"
            />
            <div v-if="trip.distance > 0">
              À <strong>{{ trip.distance }} km</strong> depuis
              {{ trip.departureCity }}
            </div>
            <div>
              <label v-if="trip.availableVehicles.length > 1"
                >Moyens de transport disponibles :
              </label>
              <label v-else>Moyen de transport disponible : </label>
              <div>
                <VehicleImage
                  v-for="(vehicle, index) in trip.availableVehicles"
                  :key="index"
                  :vehicle-name="vehicle"
                />
              </div>
            </div>
            <a
              class="trip-city-link"
              :href="trip.website"
              target="_blank"
              rel="noopener noreferrer"
              >Voir le site de la ville</a
            >
          </div>
        </div>
        <HotelCard :hotel="trip.hotel" />
      </div>

      <h2 class="title-with-separator">Votre planning</h2>
      <div class="planning-container">
        <div class="planning-summary">
          Prix complet :
          <span class="planning-price-container"
            >{{ trip.planning.total_price }} €</span
          >
          / personne
        </div>
        <div
          v-for="(day, index) in trip.planning.days"
          :key="index"
          class="day-container"
        >
          <label class="day-title" @click="toggleDay(index)">{{
            formatDate(day.day)
          }}</label>
          <div v-if="day.isOpen">
            <div v-if="day.activities.length > 0" class="activities-container">
              <div
                v-for="(activity, activityIndex) in day.activities"
                :id="`activity-${activity.id}`"
                :key="activityIndex"
                class="editable-activity-card"
              >
                <EditableActivityCard
                  :activity="activity"
                  @delete-activity="
                    openDeleteConfirmationModal(activity.id, day.day)
                  "
                  @replace-activity="handleReplaceActivity(activity, day.day)"
                />
              </div>
            </div>
            <div v-else class="no-activities">
              <p>Aucune activité prévue pour ce jour</p>
            </div>
            <button
              class="add-activity-card"
              @click.stop="handleAddActivity(day.day)"
            >
              + Ajouter une activité
            </button>
          </div>
        </div>
      </div>
    </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue';
import HotelCard from '@/components/Cards/HotelCard.vue';
import EditableActivityCard from '@/components/Cards/EditableActivityCard.vue';
import VehicleImage from '@/components/VehicleImage.vue';
import WeatherComponent from '@/components/Base/WeatherComponent.vue';
import smartVacancesApiClient from '@/SmartVacancesApiClient';
import ConfirmationModal from '@/components/Modal/ConfirmationModal.vue';
import ChooseActivityModal from '@/components/Modal/ChooseActivityModal.vue';
import NotFoundContent from '@/components/NotFoundContent.vue';

export default {
  name: 'TripView',
  components: {
    ChooseActivityModal,
    ConfirmationModal,
    WeatherComponent,
    VehicleImage,
    EditableActivityCard,
    HotelCard,
    ViewLayout,
    NotFoundContent,
  },
  props: {
    tripName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isTripNotFound: false,
      errorMsg: null,
      trip: null,
      tripWeatherIcon: null,
      isDeleteModalOpened: false,
      isChooseActivityModalOpened: false,
      chooseActivityModalType: null,
      activityToDelete: {
        id: null,
        day: null,
      },
      activityToReplace: {
        id: null,
        day: null,
      },
      dayToAddActivity: null,
      cityActivities: null,
      activitiesToChooseFrom: null,
      chooseActivityType: {
        ADD: 'add',
        REPLACE: 'replace',
      },
      openDayIndex: null,
    };
  },
  mounted() {
    this.fetchTrip(this.$route.params.tripId);
  },
  methods: {
    formatDate(dateStr) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const date = new Date(dateStr);
      return date.toLocaleDateString('fr-FR', options);
    },
    async fetchTrip(tripId) {
      try {
        const response = await smartVacancesApiClient.get(`v1/trips/${tripId}`);
        this.trip = response.data.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.isTripNotFound = true;
          return;
        }

        this.errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la spécification. Ré-essayez ultérieurement !';
      }
    },
    toggleDay(index) {
      const isOpen = this.trip.planning.days[index].isOpen;
      this.trip.planning.days[index].isOpen = !isOpen;

      const dayTitle = document.querySelector(
        `.day-title[data-index="${index}"]`,
      );
      if (dayTitle) {
        if (isOpen) {
          dayTitle.classList.add('collapsed');
        } else {
          dayTitle.classList.remove('collapsed');
        }
      }
    },
    async handleAddActivity(day) {
      if (this.cityActivities === null) {
        await this.fetchCityActivities(this.trip.destinationCity);
      }

      this.activitiesToChooseFrom = this.cityActivities;
      this.chooseActivityModalType = this.chooseActivityType.ADD;
      this.isChooseActivityModalOpened = true;
      this.dayToAddActivity = day;
    },
    openDeleteConfirmationModal(activityId, day) {
      this.activityToDelete = {
        id: activityId,
        day: day,
      };

      this.isDeleteModalOpened = true;
    },
    handleReplaceActivity(activity, day) {
      this.fetchSimilarActivities(activity.id);
      this.chooseActivityModalType = this.chooseActivityType.REPLACE;
      this.isChooseActivityModalOpened = true;
      this.activityToReplace = {
        id: activity.id,
        name: activity.name,
        day: day,
      };
    },
    async fetchSimilarActivities(activityId) {
      try {
        const response = await smartVacancesApiClient.get(
          `v1/activities/${activityId}/similar`,
        );
        this.activitiesToChooseFrom = response.data.data;
      } catch (error) {
        this.errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la récupération des activités similaires';
      }
    },
    async fetchCityActivities(cityName) {
      try {
        const response = await smartVacancesApiClient.get(
          `v1/activities/${cityName}`,
        );
        this.cityActivities = response.data.data;
      } catch (error) {
        this.errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la récupération des activités de la même ville';
      }
    },
    async confirmDeleteActivity() {
      this.isDeleteModalOpened = false;
      this.openDayIndex = this.trip.planning.days.findIndex(
        (day) => day.day === this.activityToDelete.day,
      );

      try {
        const activityCard = document.querySelector(
          `#activity-${this.activityToDelete.id}`,
        );
        const response = await smartVacancesApiClient.patch(
          `v1/trips/${this.trip.id}/activities/${this.activityToDelete.id}`,
          {
            scheduled_day: this.activityToDelete.day,
          },
        );

        activityCard.classList.add('animation-fade-out');
        setTimeout(() => {
          this.trip = response.data.data;
          this.trip.planning.days[this.openDayIndex].isOpen = true;
        }, 500);
      } catch (error) {
        this.errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la suppression';
      }
    },
    closeDeleteConfirmationModal() {
      this.isDeleteModalOpened = false;

      this.activityToDelete = {
        id: null,
        day: null,
      };
    },
    async handleChosenActivity(activity) {
      if (this.chooseActivityModalType === this.chooseActivityType.ADD) {
        this.openDayIndex = this.trip.planning.days.findIndex(
          (day) => day.day === this.dayToAddActivity,
        );
        try {
          const response = await smartVacancesApiClient.post(
            `v1/trips/${this.trip.id}/activities/add`,
            {
              activity_id: activity.id,
              scheduled_day: this.dayToAddActivity,
            },
          );
          this.trip = response.data.data;
          if (this.openDayIndex !== null) {
            this.trip.planning.days[this.openDayIndex].isOpen = true;
          }

          this.closeActivityChooseModal();

          setTimeout(() => {
            const newActivityCard = document.querySelector(
              `#activity-${activity.id}`,
            );
            if (newActivityCard) {
              newActivityCard.classList.add('activity-drop-in');

              setTimeout(() => {
                newActivityCard.classList.remove('activity-drop-in');
              }, 500);
            }
          }, 0);
        } catch (error) {
          this.errorMsg =
            error.response.data.error ??
            "Une erreur est survenue lors de l'ajout de l'activité";
        }
      } else if (
        this.chooseActivityModalType === this.chooseActivityType.REPLACE
      ) {
        this.openDayIndex = this.trip.planning.days.findIndex(
          (day) => day.day === this.activityToReplace.day,
        );
        try {
          const response = await smartVacancesApiClient.put(
            `v1/trips/${this.trip.id}/activities/${this.activityToReplace.id}/replace`,
            {
              new_activity_id: activity.id,
              scheduled_day: this.activityToReplace.day,
            },
          );
          this.trip = response.data.data;
          this.closeActivityChooseModal();

          if (this.openDayIndex !== null) {
            this.trip.planning.days[this.openDayIndex].isOpen = true;
          }
          setTimeout(() => {
            const replacedActivityCard = document.querySelector(
              `#activity-${activity.id}`,
            );
            if (replacedActivityCard) {
              replacedActivityCard.classList.add('activity-flip-in');

              setTimeout(() => {
                replacedActivityCard.classList.remove('activity-flip-in');
              }, 600);
            }
          }, 0);
        } catch (error) {
          console.error("Erreur lors de la mise à jour de l'activité:", error);
        }
      }
    },
    closeActivityChooseModal() {
      this.isChooseActivityModalOpened = false;
    },
  },
};
</script>

<style scoped>
@keyframes preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.trip-summary {
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.trip-summary-info {
  margin-bottom: 20px;
}

.choose-activity-content {
  .category-title {
    color: grey;
    font-style: italic;
    font-size: 13px;
  }
}

.trip-summary-date {
  font-weight: 800;
  padding: 1px 3px;
  border-radius: 5px;
}

.trip-summary-date-container {
  margin: 10px;
}

.trip-city-link {
  text-decoration: underline;
  color: unset;
  letter-spacing: 1px;
}

.trip-city-link:hover {
  color: var(--primaryColor);
}

.activity-content .address {
  font-size: 13px;
  display: block;
  margin: 0;
}

.planning-price-container {
  font-size: 18px;
  font-weight: bold;
}

.day-toggle-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-activity-card {
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border: 1px solid grey;
  border-style: dashed;
  color: grey;
  z-index: 10;
  margin: 5px 0;
}

.no-activities {
  font-style: italic;
  color: gray;
}

@media (max-width: 768px) {
  .trip-container {
    text-align: -webkit-center;
  }
}

@media (min-width: 1024px) {
  .trip-summary {
    display: flex;
    flex-direction: row;
  }

  .trip-summary > * {
    width: 50%;
  }

  .planning-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
}
.planning-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.day-container {
  margin: 0 10px;
  background-color: #f8f8f8;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.day-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.day-title {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.day-title::before {
  content: '▼ ';
  display: block;
  transition: transform 0.3s;
  transform: rotate(0deg);
  margin-right: 5px;
}

.day-title.collapsed::before {
  transform: rotate(-90deg);
}

.no-activities {
  font-style: italic;
  color: #888;
}

.day-container {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 5px;
}

.activities-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.animation-fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

@keyframes drop-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  60% {
    opacity: 1;
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.activity-drop-in {
  animation: drop-in 0.5s ease-out;
}

@keyframes flip-in {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.activity-flip-in {
  animation: flip-in 0.6s ease-out;
}

@media (min-width: 768px) {
  .activities-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .editable-activity-card {
    flex: 0 0 18%;
  }
}
</style>
