<template>
  <div class="address">
    <span class="address-pin">&#x1f4cd;</span>
    <span>
      <span v-if="address.streetNumber">{{ address.streetNumber }}</span>
      {{ address.streetName }}, {{ address.city }}, {{ address.country }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AddressWithPin',
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.address {
  display: flex;
  align-items: center;
  color: slategrey;
  margin-bottom: 10px;
}

.address-pin {
  margin-right: 5px;
}
</style>
