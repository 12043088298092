<template>
  <div class="hotel-card">
    <!-- <div class="tag">Private Pool</div> -->
    <div class="image-container">
      <img :src="imageSrc" alt="Hotel Image" />
      <!-- <button class="favorite-button">&#10084;</button> -->
    </div>

    <div class="info-container">
      <div class="details">
        <h3>{{ hotel.name }}</h3>
        <!-- <div class="rating">
          <span>&#9733;</span>
          <span>4.75</span>
        </div> -->
      </div>

      <AddressWithPin :address="hotel.address" />

      <div class="price">
        <span>{{ hotel.pricePerNight }} €</span>
        <span>/ nuit</span>
      </div>
      <div class="attributes">
        <!--        <div>-->
        <!--          <span>&#128719;</span>-->
        <!--          <span>4 Beds</span>-->
        <!--        </div>-->
        <!--        <div>-->
        <!--          <span>&#128101;</span>-->
        <!--          <span>6 Guests</span>-->
        <!--        </div>-->
        <div>
          <span>&#128705;</span>
          <span>1 salle de bain</span>
        </div>
        <div v-if="hotel.hasBreakfast" class="breakfast">
          <span>&#127858;</span>
          <span>Petit-déjeuner inclus</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressWithPin from '@/components/AddressWithPin.vue';

export default {
  name: 'HotelCard',
  components: { AddressWithPin },
  props: {
    hotel: {
      type: Object,
      required: true,
    },
    imageSrc: {
      type: String,
      default:
        'https://cf.bstatic.com/xdata/images/hotel/max1024x768/518332246.jpg?k=852d9e83009ac082a7bb8366d5e27fb1f21801ea9cb6dde9c14e1aa99c49ea63&o=&hp=1',
    },
  },
};
</script>

<style scoped>
.hotel-card {
  width: 450px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;
  position: relative;
}

.tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #a175ef;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.image-container {
  position: relative;
}

.image-container img {
  width: 100%;
  height: auto;
}

.favorite-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.info-container {
  padding: 15px;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.details h3 {
  margin: 0;
}

.rating {
  display: flex;
  align-items: center;
  color: #ffa500;
}

.rating span:first-child {
  margin-right: 5px;
}

.price {
  display: flex;
  align-items: baseline;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.price span:last-child {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  margin-left: 5px;
}

.attributes {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}

.attributes div {
  display: flex;
  align-items: center;
}

.attributes div span:first-child {
  margin-right: 5px;
}

.breakfast {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.breakfast span:first-child {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .hotel-card {
    width: 350px;
  }
}
</style>
