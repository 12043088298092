<template>
  <div class="trip-card">
    <div class="image-container">
      <img
        :src="trip.cityPictureUrl"
        alt="Trip city image"
        class="trip-city-image"
      />
      <div class="overlay">
        <div class="overlay-title">
          <strong>{{ trip.destinationCity }} - {{ trip.country }}</strong>
        </div>
        <div class="overlay-content">
          <div class="overlay-trip-details">
            <label
              >{{ trip.minTemperature }}°C à {{ trip.maxTemperature }}°C</label
            >
            <WeatherComponent
              :min-temperature="trip.minTemperature"
              :max-temperature="trip.maxTemperature"
            />
          </div>
          <div class="overlay-trip-details">
            <label v-if="trip.distance"
              >{{ trip.distance }} km depuis
              <b>{{ trip.departureCity }}</b></label
            >
            <img
              v-for="(vehicle, index) in trip.vehicles"
              :key="index"
              class="vehicle-logo"
              :src="require('@/assets/vehicles/v2/' + vehicle + '.png')"
              alt="Logo du véhicule"
            />
          </div>
        </div>
      </div>
    </div>
    <p>
      Du {{ formatDateToLiteral(trip.departureDate) }} au
      {{ formatDateToLiteral(trip.returnDate) }}
    </p>
    <label class="timestamps">
      Créé le {{ formatDateToNumeric(trip.createdAt) }} - Dernière modification
      le {{ formatDateToNumeric(trip.updatedAt) }}
    </label>
  </div>
</template>

<script>
import WeatherComponent from '@/components/Base/WeatherComponent.vue';
import { formatDateToNumeric, formatDateToLiteral } from '@/utils/date';

export default {
  name: 'TripCard',
  components: { WeatherComponent },
  props: {
    trip: {
      type: Object,
      required: true,
    },
  },
  methods: { formatDateToNumeric, formatDateToLiteral },
};
</script>

<style scoped>
.trip-card {
  border: 1px solid var(--primaryColor);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
  transition:
    transform 0.4s ease-in-out,
    box-shadow 0.4s ease-in-out;
}

.trip-card:hover {
  transform: translateY(-10px); /* Légère élévation de la carte */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombre plus marquée */
}

.image-container {
  position: relative;
  height: 220px;
  overflow: hidden;
}

.trip-city-image {
  width: 100%;
  object-fit: cover;
  height: 220px;
  transition: transform 0.4s ease-in-out;
  transform-origin: center;
}

.trip-card:hover .trip-city-image {
  transform: scale(1.1); /* Zoom sur l'image */
}

.overlay {
  position: absolute;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 100%;
  padding: 5px;
  text-align: center;
  z-index: 2;
}

.overlay-content {
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  gap: 15px;
}

.overlay-trip-details {
  align-items: center;
  display: inline-flex;
  font-size: 13px;

  label {
    margin-right: 7px;
  }

  img {
    width: 20px !important;
  }
}

.vehicle-logo {
  width: 30px !important;
}

.details-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
}

.timestamps {
  font-style: italic;
  color: grey;
  font-size: 12px;
  padding: 5px;
  position: relative;
  float: right;
}

@media (min-width: 768px) {
  .details-container {
    justify-content: center;
  }

  .trip-city-image {
    height: 350px;
  }

  .image-container {
    height: 350px;
  }

  .overlay {
    font-size: 24px;
  }

  .overlay-trip-details {
    font-size: 17px;

    img {
      width: 30px !important;
    }
  }
}
</style>
