<template>
  <div>
    <button @click="toggleHelp">?</button>

    <div v-if="showHelp" class="help-overlay" @click="toggleHelp">
      <div class="help-container">
        <div class="help-content">
          <h2>{{ title }}</h2>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Aide',
    },
  },
  data() {
    return {
      showHelp: false,
    };
  },
  methods: {
    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
  },
};
</script>

<style scoped>
.help-overlay {
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.83);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: scroll;
}
.help-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  text-align: -webkit-center;
  overflow: scroll;
}

.help-content {
  padding: 20px;
  border-radius: 5px;
  text-align: left;
  max-width: 500px;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  border-radius: 50px;
  padding: 1px 5px;
}

button:hover {
  background-color: #0056b3;
}
</style>
