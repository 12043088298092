<template>
  <img
    class="destination-summary-weather"
    :src="require('@/assets/weather/' + determineWeatherIcon() + '.png')"
    alt="Weather Image"
  />
</template>

<script>
export default {
  name: 'WeatherComponent',
  props: {
    minTemperature: {
      type: Number,
      required: true,
    },
    maxTemperature: {
      type: Number,
      required: true,
    },
  },
  methods: {
    determineWeatherIcon() {
      if (this.minTemperature <= 3) {
        return 'snow';
      }

      if (this.maxTemperature > 25) {
        return 'sun';
      }

      if (this.maxTemperature > 15) {
        return 'cloud-and-sun';
      }

      if (this.maxTemperature > 5) {
        return 'cloud-and-rain';
      }

      return 'cloud-and-rain';
    },
  },
};
</script>

<style scoped>
.destination-summary-weather {
  width: 50px;
}
</style>
