import { createStore } from 'vuex';
import router from '@/router';

function getAuthenticationFromLocalStorage() {
  const authData = JSON.parse(localStorage.getItem('authData'));

  if (!authData) {
    return null;
  }

  const { token, loginExpirationDate } = authData;
  const expirationDate = new Date(loginExpirationDate);

  if (new Date() > expirationDate || !token) {
    localStorage.removeItem('authData');
    return null;
  }

  return authData;
}

const store = createStore({
  state: {
    authData: getAuthenticationFromLocalStorage(),
    isAuthenticated: !!getAuthenticationFromLocalStorage(),
    shouldDisplayTripCreationBtn: false,
  },
  mutations: {
    SET_AUTHENTICATION(state, authData) {
      state.authData = authData;
      localStorage.setItem('authData', JSON.stringify(authData));
    },
    CLEAR_AUTHENTICATION(state) {
      state.authData = null;
      localStorage.removeItem('authData');
    },
    SET_SHOULD_DISPLAY_TRIP_CREATION_BTN(state, shouldDisplayTripCreationBtn) {
      state.shouldDisplayTripCreationBtn = shouldDisplayTripCreationBtn;
    },
  },
  actions: {
    applyLogin({ commit }, token) {
      const loginExpirationDate = new Date();
      loginExpirationDate.setDate(loginExpirationDate.getDate() + 7); // 7 jours

      const authData = {
        token,
        loginExpirationDate: loginExpirationDate.toISOString(),
      };
      commit('SET_AUTHENTICATION', authData);
    },
    applyLogout({ commit }) {
      commit('CLEAR_AUTHENTICATION');
      router.push({ name: 'Homepage' });
    },
    setShouldDisplayTripCreationBtn({ commit }, shouldDisplayTripCreationBtn) {
      commit(
        'SET_SHOULD_DISPLAY_TRIP_CREATION_BTN',
        shouldDisplayTripCreationBtn,
      );
    },
  },
  getters: {
    isAuthenticated: (state) => {
      if (!state.authData) {
        return false;
      }

      const { token, loginExpirationDate } = state.authData;
      const expirationDate = new Date(loginExpirationDate);

      return token !== null && new Date() <= expirationDate;
    },
    token: (state) => {
      if (
        state.authData &&
        new Date() < new Date(state.authData.loginExpirationDate)
      ) {
        return state.authData.token;
      } else {
        return null;
      }
    },
    shouldDisplayTripCreationBtn: (state) => state.shouldDisplayTripCreationBtn,
  },
});

export default store;
