<template>
  <div id="prompt-container">
    <div class="text-with-tooltip-container">
      <label id="describe-project-label"> Décrivez votre projet </label>
      <HelpTooltip
        title="Racontez-nous votre idéal de vacances, sans limitation."
      >
        <div>
          <label
            >En manque d'inspiration ? Précisez par exemple certains de ces
            critères :</label
          >
          <ul class="help-tooltip-list">
            <li>
              <b>Budget :</b> Nous avons un budget limité, donc nous recherchons
              des options économiques.
            </li>
            <li>
              <b>Durée du Séjour :</b> Nous prévoyons de partir pendant environ
              X jours/semaines.
            </li>
            <li>
              <b>Destinations Préférées :</b> Nous sommes ouverts à différentes
              destinations, mais nous aimerions savoir quelles sont les options
              disponibles pour des voyages depuis Paris en août.
            </li>
            <li>
              <b>Type d'Hébergement :</b> Nous préférons [Hôtels / Airbnb /
              Auberges de jeunesse / Autre].
            </li>
            <li>
              <b>Activités :</b> Nous aimerions participer à des activités
              telles que [Plage / Randonnée / Visites Culturelles / Shopping].
            </li>
            <li>
              <b>Transport :</b> Nous prévoyons de voyager en [Avion / Train /
              Bus / Voiture].
            </li>
            <li>
              <b>Climat Souhaité :</b> Nous apprécierions un climat [Chaud /
              Tempéré / Frais] pour nos vacances.
            </li>
            <li>
              <b>Préférences Alimentaires :</b> Nous avons des préférences
              alimentaires, notamment [Végétariennes / Végétaliennes / Non
              spécifiées].
            </li>
            <li>
              <b>Intérêts Spécifiques :</b> Nous sommes intéressés par [Nature /
              Vie Nocturne / Culture / Autre].
            </li>
          </ul>
        </div>
      </HelpTooltip>
    </div>

    <div id="prompt-type-selector">
      <label
        class="underlined-label"
        :class="{ selected: isTextualPromptActive }"
        @click="toggleTextualPrompt"
      >
        À l'écrit
      </label>
      <label>|</label>
      <label
        class="underlined-label"
        :class="{ selected: !isTextualPromptActive }"
        @click="toggleAudioPrompt"
      >
        À l'oral
      </label>
    </div>

    <div
      v-show="!audioCriteriaLoading && isTextualPromptActive"
      class="textual-prompt"
    >
      <div>
        <div>
          <textarea
            id="criteria-input"
            v-model="criteria.value"
            :placeholder="animatedPlaceholder"
            :disabled="loading"
            @input="onCriteriaInput"
          />
        </div>
        <div class="progress-bar-container">
          <ButtonWithGradient
            v-if="!loading"
            id="generate-destination-suggestion-btn"
            message="Générer"
            @click="generateHolidayPlanning"
          />
        </div>
        <span v-if="criteria.error" class="error-message">{{
          criteria.error
        }}</span>
      </div>
    </div>

    <div
      v-show="!textCriteriaLoading && !isTextualPromptActive"
      class="audio-prompt"
    >
      <AudioRecorder @audio-recorded="generateHolidayPlanningFromAudio" />
      <span v-if="criteria.audioError" class="error-message">{{
        criteria.audioError
      }}</span>
    </div>

    <div v-if="loading">
      <ProgressBar :progress="progress" :msg="progressingMessage" />

      <h2 class="loading-destination-suggestion-introduction" />

      <LoadingDestinationCard />
      <LoadingDestinationCard />
      <LoadingDestinationCard />
    </div>

    <div v-if="planning" id="planning-container">
      <h2>{{ planning.introduction }}</h2>
      <DestinationsList :destinations="planning.destinations" />
    </div>
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue';
import HelpTooltip from './Base/HelpTooltip.vue';
import AudioRecorder from './AudioRecorder.vue';
import DestinationsList from '@/components/DestinationsList.vue';
import LoadingDestinationCard from '@/components/Cards/LoadingDestinationCard.vue';
import smartVacancesApiClient from '@/SmartVacancesApiClient';
import ButtonWithGradient from '@/components/Base/ButtonWithGradient.vue';
import { getObjectFromCookie, setObjectInCookie } from '@/utils/cookies';

export default {
  name: 'PromptComponent',
  components: {
    ButtonWithGradient,
    LoadingDestinationCard,
    DestinationsList,
    ProgressBar,
    HelpTooltip,
    AudioRecorder,
  },
  data() {
    return {
      criteria: {
        value: '',
        error: '',
        audioError: '',
      },
      isTextualPromptActive: true,
      loading: false,
      textCriteriaLoading: false,
      audioCriteriaLoading: false,
      planning: null,
      progress: 0,
      progressingMessage: 'En cours de recherche...',
      animatedPlaceholder: '',
      placeholders: [
        'Je suis étudiante à Paris, je veux des vacances pas chères avec mes 2 meilleures amies en août.',
        'Nous voulons partir en couple pour un voyage romantique en Italie.',
        'Je recherche des vacances sportives avec beaucoup de randonnée en montagne.',
        'On aimerait partir en famille avec nos enfants de 5 et 8 ans pour des vacances relaxantes.',
      ],
      messages: [
        'Recherche de la crème solaire perdue...',
        'Localisation des chaussettes disparues dans la valise...',
        "Dépoussiérage du passeport et des rêves d'évasion...",
        'Enquête sur le mystère de la valise qui refuse de se fermer...',
        'Calcul du temps nécessaire pour bronzer sans brûler...',
        'Test de résistance des maillots de bain face aux vagues...',
        'Étude des cartes pour trouver le chemin le plus sinueux...',
        'Examen des restaurants locaux pour repérer les meilleurs desserts...',
        'Évaluation des compétences en matière de châteaux de sable...',
        "Création d'une playlist spéciale pour chanter faux en voiture...",
        'Recherche des skis parfaits...',
        'Localisation des gants égarés dans la neige...',
        'Dépoussiérage de la combinaison de ski...',
        'Enquête sur le mystère de la boisson chaude qui refroidit trop vite...',
        'Calcul du nombre de couches nécessaires pour braver le froid...',
        'Test de résistance des bonhommes de neige face aux rayons du soleil...',
        'Étude des pistes pour trouver les meilleures descentes...',
        "Examen des restaurants d'altitude pour repérer les meilleurs plats...",
        'Évaluation des compétences en matière de batailles de boules de neige...',
        "Création d'une playlist spéciale pour dévaler les pentes en musique...",
        'Préparation du chocolat chaud ultime pour se réchauffer après une journée de ski...',
      ],
      currentPlaceholderIndex: 0,
      charIndex: 0,
      typingInterval: null,
      pauseDuration: 2000,
      typingSpeed: 75,
      pause: false,
    };
  },
  mounted() {
    this.startTyping();

    const lastCriteria = getObjectFromCookie('lastCriteria');
    if (lastCriteria) {
      this.criteria.value = lastCriteria;
    }

    const destinationsSuggestion = getObjectFromCookie(
      'destinationsSuggestion',
    );
    if (destinationsSuggestion) {
      this.planning = destinationsSuggestion;
    }
  },
  beforeUnmount() {
    clearInterval(this.typingInterval);
  },
  methods: {
    toggleTextualPrompt() {
      this.isTextualPromptActive = true;
    },
    toggleAudioPrompt() {
      this.isTextualPromptActive = false;
    },
    typePlaceholder() {
      if (this.pause) return;

      const currentText = this.placeholders[this.currentPlaceholderIndex];

      if (this.charIndex < currentText.length) {
        this.animatedPlaceholder += currentText.charAt(this.charIndex);
        this.charIndex++;
      } else {
        clearInterval(this.typingInterval);
        setTimeout(() => {
          this.moveToNextPlaceholder();
        }, this.pauseDuration);
      }
    },
    moveToNextPlaceholder() {
      this.currentPlaceholderIndex =
        (this.currentPlaceholderIndex + 1) % this.placeholders.length;
      this.animatedPlaceholder = '';
      this.charIndex = 0;
      this.startTyping();
    },
    startTyping() {
      this.typingInterval = setInterval(this.typePlaceholder, this.typingSpeed);
    },
    pauseAnimation() {
      this.pause = true;
    },
    resumeAnimation() {
      this.pause = false;
    },
    async generateHolidayPlanning() {
      this.planning = null;
      this.criteria.error = '';
      if (this.criteria.value.length < 10) {
        this.criteria.error =
          'Vos critères doivent contenir au moins 10 caractères.';
        return;
      }

      this.audioCriteriaLoading = false;
      this.textCriteriaLoading = true;
      this.launchProgressBar();

      try {
        const response = await smartVacancesApiClient.post(
          'v1/destinations-suggestion',
          {
            criteria: this.criteria.value,
          },
        );

        this.progress = 100;
        this.planning = response.data.data;

        setObjectInCookie('lastCriteria', this.criteria.value, 1);
        setObjectInCookie('destinationsSuggestion', this.planning, 1);

        clearInterval(this.progressInterval);
        clearInterval(this.messageInterval);
      } catch (error) {
        let errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la génération. Essayez de préciser vos intentions, par exemple en ajoutant des dates de voyage, un point de départ, un budget, etc...';
        if (errorMsg === 'Error while generating destinations suggestion') {
          errorMsg =
            "Une erreur est survenue lors de la génération. Elle est indépendante de votre requête. Nous vous remercions de bien vouloir l'effectuer de nouveau, et nous excusons pour le désagrément.";
        }

        this.criteria.error = errorMsg;
        this.resetLoadingVariables();
      }

      this.resetLoadingVariables();
    },
    async generateHolidayPlanningFromAudio(audioCriteria) {
      this.planning = null;
      this.criteria.audioError = '';
      this.audioCriteriaLoading = true;
      this.textCriteriaLoading = false;
      this.launchProgressBar();

      try {
        const body = new FormData();
        body.append('file', audioCriteria, 'audio-criteria.mp3');

        const response = await smartVacancesApiClient.post(
          'v1/destinations-suggestion/audio',
          body,
        );

        this.progress = 100;
        this.planning = response.data.data;

        clearInterval(this.progressInterval);
        clearInterval(this.messageInterval);
      } catch (error) {
        let errorMsg =
          error.response.data.error ??
          'Une erreur est survenue lors de la génération. Essayez de préciser vos intentions, par exemple en ajoutant des dates de voyage, un point de départ, un budget, etc...';
        if (errorMsg === 'Error while generating holiday planning') {
          errorMsg =
            "Une erreur est survenue lors de la génération. Elle est indépendante de votre requête. Nous vous remercions de bien vouloir l'effectuer de nouveau, et nous excusons pour le désagrément.";
        }

        this.criteria.audioError = errorMsg;
        this.resetLoadingVariables();
      }

      this.resetLoadingVariables();
    },
    onCriteriaInput() {
      this.criteria.error = '';
    },
    launchProgressBar() {
      this.progress = 3;
      this.loading = true;
      this.progressInterval = setInterval(() => {
        if (this.progress <= 97) {
          this.progress += 3;
        }
      }, 300);

      this.messageInterval = setInterval(() => {
        if (this.loading) {
          this.progressingMessage = this.getRandomMessage();
        }
      }, 15000);
    },
    resetLoadingVariables() {
      this.loading = false;
      this.audioCriteriaLoading = false;
      this.textCriteriaLoading = false;
    },
    getRandomMessage() {
      const randomIndex = Math.floor(Math.random() * this.messages.length);
      return this.messages[randomIndex];
    },
  },
};
</script>

<style scoped>
.underlined-label {
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px 3px 10px;
  position: relative;
  margin-bottom: 5px !important;
}

.underlined-label::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  background: none repeat scroll 0 0 transparent;
  height: 2px;
  width: 0;
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  transition:
    width 0.3s ease 0s,
    left 0.3s ease 0s;
}

.underlined-label:not(.selected):hover::after {
  width: 100%;
  left: 0;
}

#criteria-input {
  height: 70px;
  padding: 5px;
  width: 100%;
  resize: none;
  border-radius: 5px;
  font-family: sans-serif;
  box-sizing: border-box;
  font-size: 16px;
  transition: box-shadow 300ms;
}

#criteria-input:disabled {
  font-style: italic;
  cursor: not-allowed;
  color: grey;
  background-color: #d3d3d373;
}

#criteria-input:focus-visible {
  outline: none;
  box-shadow: 0.5px 1px 2px black;
}

#generate-destination-suggestion-btn {
  margin-top: 10px;
}

#describe-project-label {
  margin-right: 7px;
}

#prompt-type-selector {
  margin: 5px;
}

#prompt-type-selector .selected {
  font-weight: 900;
}

#prompt-type-selector label:not(.selected) {
  cursor: pointer;
}

#prompt-type-selector label {
  margin: 0 20px;
}

.text-with-tooltip-container {
  display: inline-flex;
  align-items: center;
  margin: 20px 0;
}

.help-tooltip-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.help-tooltip-list > li {
  margin: 10px 0;
}

.loading-destination-suggestion-introduction {
  height: 50px;
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@media (max-width: 600px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  #criteria-input {
    width: 400px;
  }

  #prompt-type-selector label {
    margin: 0 20px;
  }
}

@media (min-width: 1280px) {
}
</style>
