<template>
  <div class="loading-destination-card">
    <h3 />
    <div class="city-name" />
    <div class="temperature" />
    <div class="distance" />
    <div class="vehicle-logo" />
    <p />
    <div class="website-link" />
  </div>
</template>

<script>
export default {
  name: 'LoadingDestinationCard',
};
</script>

<style scoped>
.loading-destination-card {
  padding: 5px;
  margin: 20px 5px;
  list-style: none;
  border-radius: 5px;
  border: 1px solid #ececec;
  border-left: 15px solid #ececec;
  text-align: -webkit-center;

  h3,
  p,
  div {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  div {
    height: 22px;
    width: 65%;
    margin: 5px;
  }

  .city-name {
    width: 150px;
  }

  .temperature {
    width: 80px;
  }

  .distance {
    width: 65px;
  }

  .vehicle-logo {
    width: 100px;
    height: 50px;
  }

  h3 {
    width: 85%;
    height: 30px;
  }

  p {
    height: 130px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@media (min-width: 1024px) {
  .loading-destination-card {
    h3 {
      width: 55%;
    }

    p {
      width: 95%;
    }

    .website-link {
      width: 170px;
    }
  }
}
</style>
