<template>
  <ViewLayout>
    <div id="homepage-container">
      <div class="intro-container">
        <h1 class="title">
          Vos prochaines vacances,
          <label class="primary-color-label">sans effort</label>
        </h1>
        <p class="centered-paragraph">
          Générez des suggestions de destinations qui vous ressemblent,
          transformez-les en vos prochaines vacances de rêve en en modifiant
          chaque aspect. Conçu pour les voyageurs qui veulent profiter de leurs
          vacances sans passer des heures à les organiser.
        </p>

        <router-link :to="{ name: 'GenerateDestinationsSuggestion' }">
          <ButtonWithGradient message="Créer un nouveau voyage" />
        </router-link>
      </div>

      <LoadingHolidayBanner />

      <div class="section">
        <div class="content-container">
          <div class="text-section">
            <h2 class="subtitle">
              Votre voyage
              <label class="primary-color-label">boosté par l'IA</label>
            </h2>
            <p class="centered-paragraph">
              Nous utilisons l'intelligence artificielle pour vous proposer des
              destinations qui correspondent à vos envies, et aux moindres
              détails que vous nous transmettez.
            </p>
          </div>
          <div class="image-section">
            <img
              :src="require('@/assets/beach.png')"
              alt="beach"
              class="icon"
            />
            <img
              :src="require('@/assets/volley-ball.png')"
              alt="volley-ball"
              class="smaller-icon"
            />
          </div>
        </div>
      </div>

      <div class="section">
        <div class="content-container">
          <div class="image-section">
            <img :src="require('@/assets/map.png')" alt="map" class="icon" />
          </div>
          <div class="text-section">
            <h2 class="subtitle subtitle-all-in-one">
              Tout en <label class="primary-color-label">un</label> !
            </h2>
            <p class="centered-paragraph">
              Dites adieu au stress de la planification ! Générez des
              suggestions de destinations, peaufinez-les à votre goût puis
              réservez votre hébergement, vos activités et vos transports en un
              seul endroit.
            </p>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="content-container">
          <div class="image-section">
            <img
              :src="require('@/assets/handshake.png')"
              alt="handshake"
              class="icon"
            />
          </div>
        </div>
        <div class="text-section">
          <h2 class="subtitle">
            Plus de <label class="primary-color-label">3000</label> destinations
            déjà générées
          </h2>
          <p class="centered-paragraph">
            Merci pour votre fidélité ! <br />
            Nous travaillons depuis 2023 pour vous proposer la meilleure
            expérience de planification de voyage.
          </p>
        </div>
      </div>

      <div>
        <h2 class="subtitle title-with-separator">
          Consultez des destinations déjà générées par d'autres utilisateurs
        </h2>

        <LoadingDestinationCard v-show="!homepageDestinations" />

        <SliderDestinationsList
          :destinations="homepageDestinations"
          :is-slider="true"
        />
      </div>

      <div class="faq-container">
        <h2 class="subtitle title-with-separator">
          Questions fréquemment posées
        </h2>

        <FaqSidebar />
      </div>

      <!--      <div class="section">-->
      <!--        <div class="content-container">-->
      <!--          <h2 class="subtitle">-->
      <!--            Plus de <label class="primary-color-label">307</label> destinations et <label class="primary-color-label">452</label> activités à découvrir-->
      <!--          </h2>-->
      <!--          <p class="centered-paragraph">-->
      <!--            En plus de pouvoir générer vos propres idées à l'oral ou à l'écrit, vous pouvez piocher dans une vaste bibliothèque de destinations et d'activités pour enrichir votre voyage.-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </div>-->
      <div v-show="showStickyButton" class="sticky-footer">
        <router-link :to="{ name: 'GenerateDestinationsSuggestion' }">
          <button class="create-destination-suggestion-btn">
            Créer un nouveau voyage
          </button>
        </router-link>
      </div>
    </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue';
import SliderDestinationsList from '@/components/SliderDestinationsList.vue';
import GenerateDestinationsSuggestion from '@/views/GenerateDestinationsSuggestion.vue';
import smartVacancesApiClient from '@/SmartVacancesApiClient';
import ButtonWithGradient from '@/components/Base/ButtonWithGradient.vue';
import LoadingDestinationCard from '@/components/Cards/LoadingDestinationCard.vue';
import FaqSidebar from '@/components/FaqSidebar.vue';
import LoadingHolidayBanner from '@/components/Cards/LoadingHolidayBanner.vue';

export default {
  name: 'HomepageView',
  components: {
    LoadingHolidayBanner,
    FaqSidebar,
    LoadingDestinationCard,
    ButtonWithGradient,
    SliderDestinationsList,
    ViewLayout,
  },
  data() {
    return {
      modalOpen: true,
      homepageDestinations: undefined,
      showStickyButton: false,
    };
  },
  computed: {
    GenerateDestinationsSuggestion() {
      return GenerateDestinationsSuggestion;
    },
  },
  mounted() {
    this.getHomepageDestinations();
  },
  methods: {
    async getHomepageDestinations() {
      try {
        const response = await smartVacancesApiClient.get(
          'v1/destinations/sample',
        );

        this.homepageDestinations = response.data.data.destinations;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.icon {
  width: 100px;
}

.smaller-icon {
  width: 85px;
  height: 85px;
}

#homepage-container {
  margin: 8px;
  text-align: center;
}

.intro-container {
  text-align: center;
  text-align: -webkit-center;
}

.title {
  font-size: 42px;
  line-height: 40px;
  margin-top: 50px;
  font-weight: 900;
  letter-spacing: -2px;
}

.subtitle {
  font-size: 28px;
  line-height: 30px;
  margin-top: 0px;
  font-weight: 900;
  letter-spacing: -1px;
  padding-top: 5px;
}

.subtitle-all-in-one {
  margin-top: 10px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
  text-align: -webkit-center;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.text-section {
  width: 100%;
}

.image-section {
  display: flex;
  justify-content: center;
  gap: 10px;
}

@media (min-width: 1024px) {
  #homepage-container {
    .intro-container {
      .title {
        font-size: 60px;
        line-height: 30px;
      }
    }

    .subtitle {
      font-size: 38px;
    }

    .subtitle-all-in-one {
      margin-top: 0px;
    }

    .content-container {
      flex-direction: row;
      text-align: left;
    }

    .text-section {
      width: 60%;
      margin-bottom: 0;
    }

    .image-section {
      width: 40%;
      justify-content: center;
    }

    .section-title {
      font-size: 34px;
    }

    .faq-container {
      margin-top: 25px;
    }
  }
}
</style>
