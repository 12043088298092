<template>
  <ViewLayout>
    <NotFoundContent />
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue';
import NotFoundContent from '@/components/NotFoundContent.vue';

export default {
  name: 'NotFound',
  components: {
    ViewLayout,
    NotFoundContent,
  },
};
</script>

<style></style>
