<template>
  <section>
    <div class="error-404-container">
      <div class="image-error-404-container">
        <img
          class="image-error-404"
          :src="require('@/assets/404.png')"
          alt="Erreur 404"
        />
      </div>
      <div class="text-error-404">
        <h2 class="title-with-separator">Oups!</h2>
        <h3>
          Nous ne pouvons pas trouver {{ notFoundContent }} que vous avez
          demandé.
        </h3>
        <p>Voici des liens utiles à la place :</p>
        <ul class="links-error-404-container">
          <li class="links-error-404">
            <router-link :to="{ name: 'Homepage' }">Page d'acceuil</router-link>
          </li>
          <li class="links-error-404">
            <router-link :to="{ name: 'GenerateDestinationsSuggestion' }"
              >Générer un voyage</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFoundContent',
  props: {
    notFoundContent: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.error-404-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.text-error-404 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.text-error-404 h2 {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 5px;
  margin: 0;
}

.text-error-404 h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin: 0 10px 0 10px;
  width: auto;
}

.text-error-404 p {
  margin-top: 20px;
  font-size: 20px;
  width: auto;
}

.links-error-404-container {
  list-style: none;
  font-size: 17px;
  padding: 0;
}

.links-error-404 {
  text-align: center;
}

.image-error-404-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.image-error-404 {
  object-fit: cover;
  height: 250px;
}

@media (min-width: 1024px) {
  .error-404-container {
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .text-error-404 {
    order: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .text-error-404 h2 {
    font-size: 50px;
    width: 100%;
    text-align: left;
    margin: 15px 0 15px 0;
  }

  .title-with-separator::before,
  .title-with-separator::after {
    display: none;
  }

  .text-error-404 h3 {
    max-width: 80%;
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
  }

  .text-error-404 p {
    text-align: left;
    margin: 0;
    font-size: 25px;
  }

  .links-error-404 {
    text-align: left;
    color: white;
    font-size: 17px;
  }

  .links-error-404 a:hover {
    color: var(--primaryColor);
  }

  .image-error-404-container {
    width: 50%;
    order: 2;
  }

  .image-error-404 {
    object-fit: cover;
    height: 350px;
    width: 400px;
  }
}
</style>
