<template>
  <div>
    <HeaderSticky @create-trip="$emit('create-trip')" />
    <slot />

    <FooterComponent />
  </div>
</template>

<script>
import HeaderSticky from '@/components/HeaderSticky.vue';
import FooterComponent from '@/components/Base/Footer.vue';

export default {
  components: {
    HeaderSticky,
    FooterComponent,
  },
  emits: ['create-trip'],
};
</script>

<style></style>
