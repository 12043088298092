<template>
  <div class="addable-activity-card">
    <div class="card-header">
      <div v-for="tag in activity.tags" :key="tag.id" class="tag">
        {{ tag.label }}
      </div>
      <button class="add-btn" @click.stop="addActivity(activity)">+</button>
    </div>

    <div class="activity-image">
      <img :src="imageSrc" alt="Activity Image" />
    </div>

    <div class="activity-content">
      <h4>{{ activity.name }}</h4>
      <p>{{ activity.description }}</p>
      <div v-if="activity.estimated_cost_by_person > 0" class="price">
        <span>{{ activity.estimated_cost_by_person }} €</span>
        <span>/ personne</span>
      </div>
      <div v-else class="free">
        <span>Gratuit</span>
      </div>
      <AddressWithPin v-if="activity.address" :address="activity.address" />
    </div>
  </div>
</template>

<script>
import AddressWithPin from '@/components/AddressWithPin.vue';

export default {
  name: 'AddableActivityCard',
  components: { AddressWithPin },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    imageSrc: {
      type: String,
      default:
        'https://static.vecteezy.com/ti/vecteur-libre/t2/3002270-randonnee-activite-en-montagne-vectoriel.jpg',
    },
  },
  emits: ['add-activity'],
  methods: {
    addActivity(activity) {
      this.$emit('add-activity', activity);
    },
  },
};
</script>

<style scoped>
.addable-activity-card {
  position: relative;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 80%;
  margin: 5px 0;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.addable-activity-card:hover .card-header {
  max-height: 50px;
  opacity: 1;
  transform: translateY(0);
}

.card-header {
  position: absolute;
  top: 10px;
  right: 10px;
  gap: 5px;
  display: flex;
  transition:
    opacity 0.5s ease,
    max-height 0.5s ease,
    transform 0.5s ease;
  transform: translateY(-20px);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.add-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.add-btn {
  background-color: forestgreen;
  font-size: 19px;
  font-weight: bold;
}

.tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #a175ef;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.add-btn:hover {
  opacity: 0.75;
}

.activity-image img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-bottom: 1px solid #eee;
}

.activity-content {
  padding: 7px 12px;
}

.activity-content {
  h4 {
    font-size: 16px;
    margin: 0 0 5px;
  }

  p {
    font-size: 14px;
    margin: 8px;
  }
}

.price {
  align-items: baseline;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.free {
  align-items: baseline;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: seagreen;
}

.price span:last-child {
  font-size: 14px;
  font-weight: normal;
  color: #666;
  margin-left: 5px;
}

@media (min-width: 768px) {
  .addable-activity-card {
    margin: 20px 0;
    width: 200px;
  }
}
</style>
