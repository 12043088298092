<template>
  <button class="button-with-gradient">
    {{ message }}
  </button>
</template>

<script>
export default {
  name: 'ButtonWithGradient',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.button-with-gradient {
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  color: #ffffff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
  font-size: 17px;
  line-height: 2.5;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  transition:
    box-shadow 0.2s ease-in-out,
    background-color 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-with-gradient:not([disabled]):focus,
.button-with-gradient:not([disabled]):hover {
  box-shadow:
    0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem rgba(var(--hoverColorRGB), 0.5),
    0.125rem 0.125rem 1rem rgba(var(--primaryColorRGB), 0.5);
}

.button-with-gradient:not([disabled]):hover {
  background: linear-gradient(
    to bottom right,
    var(--primaryColor),
    var(--hoverColor)
  );
}
</style>
