<template>
  <ModalComponent :is-open="true" @close="closeModal">
    <div>
      <h2>{{ msg }}</h2>
    </div>

    <div class="confirmation-buttons-container">
      <ButtonWithGradient message="Oui" @click="confirm" />

      <ButtonWithGradient message="Non" @click="doNotConfirm" />
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from '@/components/Base/Modal.vue';
import ButtonWithGradient from '@/components/Base/ButtonWithGradient.vue';

export default {
  name: 'ConfirmationModal',
  components: { ButtonWithGradient, ModalComponent },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    msg: {
      type: String,
      required: true,
    },
  },
  emits: ['confirmation-modal-yes', 'confirmation-modal-no', 'close'],
  methods: {
    confirm() {
      this.$emit('confirmation-modal-yes');
      this.closeModal();
    },
    doNotConfirm() {
      this.$emit('confirmation-modal-no');
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.confirmation-buttons-container {
  display: flex;
  justify-content: center;
  gap: 5px;
}

@media (min-width: 768px) {
  button {
    font-size: 16px;
  }
}
</style>
