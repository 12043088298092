<template>
  <MobileFullScreenLargeDesktopModal :is-open="true" @close="closeModal">
    <slot />
    <div class="activities-container">
      <AddableActivityCard
        v-for="activity in activities"
        :key="activity.id"
        :activity="activity"
        @add-activity="activityChosen(activity)"
      />
    </div>
  </MobileFullScreenLargeDesktopModal>
</template>

<script>
import MobileFullScreenLargeDesktopModal from '@/components/Base/MobileFullScreenLargeDesktopModal.vue';
import AddableActivityCard from '@/components/Cards/AddableActivityCard.vue';

export default {
  name: 'ChooseActivityModal',
  components: {
    AddableActivityCard,
    MobileFullScreenLargeDesktopModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
    },
  },
  emits: ['activity-chosen', 'close'],
  methods: {
    activityChosen(activity) {
      this.$emit('activity-chosen', activity);
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.activities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  button {
    font-size: 16px;
  }

  .activities-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
}
</style>
