<template>
  <div class="audio-recorder-container">
    <button
      id="record-audio-criteria-btn"
      :class="{ recording: isRecording }"
      @click="toggleRecording"
    >
      <img
        class="microphone-logo"
        :src="require('@/assets/microphone-5.png')"
        alt="Logo de microphone"
      />
    </button>
  </div>
  <span v-if="errorMsg" class="error-message">{{ errorMsg }}</span>
</template>

<script>
export default {
  emits: ['audio-recorded'],
  data() {
    return {
      mediaRecorder: null,
      audioChunks: [],
      isRecording: false,
      errorMsg: '',
    };
  },
  methods: {
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/mp3' });

            // emit custom event to be listened by parent
            this.$emit('audio-recorded', audioBlob);
          };

          this.mediaRecorder.start();
          this.isRecording = true;
        })
        .catch((error) => {
          this.errorMsg =
            "Nous n'avons pas accès au microphone de votre device";
          console.error("Erreur lors de l'accès au microphone :", error);
        });
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.isRecording = false;
        this.audioChunks = [];
      }
    },
  },
};
</script>

<style scoped>
.audio-recorder-container {
  padding: 5px;
}

#record-audio-criteria-btn {
  position: relative;
  display: inline-block;
  margin: 20px;
}

#record-audio-criteria-btn .microphone-logo {
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  display: block;
  position: relative;
  padding: 12px 12px;
  border-radius: 50px;
  cursor: pointer;
}

#record-audio-criteria-btn .microphone-logo:hover {
  background: linear-gradient(
    to bottom right,
    var(--primaryColor),
    var(--hoverColor)
  );
}

#record-audio-criteria-btn .microphone-logo:active {
  top: 10px;
  background-color: #f78900;

  -webkit-box-shadow:
    inset 0 1px 0 #ffe5c4,
    inset 0 -3px 0 var(--secondaryColor);
  -moz-box-shadow:
    inset 0 1px 0 #ffe5c4,
    inset 0 -3pxpx 0 var(--secondaryColor);
  box-shadow:
    inset 0 1px 0 #ffe5c4,
    inset 0 -3px 0 var(--secondaryColor);
}

#record-audio-criteria-btn {
  border: none;
  background: none;
}

.microphone-logo {
  width: 35px;
  padding: 5px;
}

.recording .microphone-logo {
  animation: recording 1.2s ease infinite;
}

@keyframes recording {
  from,
  to {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
  }
}
</style>
