<template>
  <div class="progress-bar-container">
    <div class="progress-bar">
      <div class="progress" :style="{ width: progress + '%' }" />
    </div>
    <div class="progress-message">
      {{ msg }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    msg: {
      type: String,
      default: '',
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.progress-bar {
  width: 100%;
  max-width: 400px;
  height: 25px;
  border-radius: 5px;
  background-color: #ccc;
}

.progress {
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(
    to bottom right,
    var(--hoverColor),
    var(--primaryColor)
  );
  transition: width 0.3s linear;
}

.progress-message {
  top: 0;
  bottom: 0;
  padding: 5px;
  display: flex;
  color: white;
  align-items: center;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;

  /* make a decision - oblique */
  transform: skewX(-15deg);
}

@media (min-width: 600px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .progress-bar {
    max-width: 400px;
  }

  .progress-message {
    font-size: 15px;
    font-weight: 800;
  }
}

@media (min-width: 1280px) {
}
</style>
